<template>
  <div class="StreamOnboardingStep2">
    <heading class="StreamOnboardingStep__counter" size="6">
      {{ $t('pages.stream_onboarding.step_count', { current:currentStep , total:totalSteps }) }}
    </heading>
    <heading size="3">
      {{ $t('pages.stream_onboarding.step_2.heading') }}
    </heading>
    <heading size="5">
      {{ $t('pages.stream_onboarding.step_2.sub_heading') }}
    </heading>
    <heading size="5">
      {{ $t('pages.stream_onboarding.step_2.sub_heading_2') }}
    </heading>
    <form @submit.prevent="proceed">
      <form-wrapper :validator="validator">
        <form-field
          :label="$t('general.phone_number')"
          name="phone"
        >
          <input
            v-model.lazy="validator.$model"
            slot-scope="{ validator, attrs }"
            v-bind="attrs"
            class="input"
            type="text"
          >
        </form-field>
        <form-field
          :label="$t('forms.organisation_name')"
          name="organisation_name"
        >
          <input
            v-model="validator.$model"
            slot-scope="{ validator, attrs }"
            v-bind="attrs"
            class="input"
            type="text"
          >
        </form-field>
        <form-field
          :label="$t('forms.role_position')"
          name="role"
        >
          <input
            v-model="validator.$model"
            slot-scope="{ validator, attrs }"
            v-bind="attrs"
            class="input"
            type="text"
          >
        </form-field>
        <form-field
          :label="$t('pages.stream_onboarding.step_2.password_label')"
          name="password"
        >
          <input
            v-model="validator.$model"
            slot-scope="{ validator, attrs }"
            v-bind="attrs"
            class="input"
            type="password"
          >
        </form-field>
      </form-wrapper>
      <div class="m-t-m has-text-right">
        <v-button :loading="isLoading" class="is-primary m-b-m">
          {{ $t('pages.stream_onboarding.step_2.finish') }}
        </v-button>
        <div>
          {{ $t('pages.stream_onboarding.step_2.finish_small_print') }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import OnboardingStepPrototype, { createComputedFormField } from '@/components/onboarding/OnboardingStepPrototype'

/**
 * @module StreamOnboardingStep2
 */
export default {
  name: 'StreamOnboardingStep2',
  extends: OnboardingStepPrototype,
  computed: {
    organisation_name: createComputedFormField('organisation_name'),
    phone: createComputedFormField('phone'),
    role: createComputedFormField('role')
  }
}
</script>
